<template>
  <div
    class="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-4"
  >
    <div
      class="max-w-sm rounded overflow-hidden shadow-lg mx-3"
      v-for="(order, key) in ordersData"
      :key="key"
    >
      <div class="font-bold mb-2 bg-green-500 p-3">
        <div class="flex justify-between">
          <p class="text-white">{{ getTimeDiff(order.created_at) }}</p>
          <p class="text-white">{{ order.code }}</p>
        </div>
      </div>
      <p class="text-black font-bold text-sm bg-[#bdbdbdbd]">{{ order.type }}</p>
      <div
        class="px-4 py-2 shadow-sm"
        v-for="(product, index) in order.orderProducts"
        :key="index"
      >
        <div class="border-b-1 border-gray-300 mb-1">
          <p class="text-left">
            {{ product.quantity }}x {{ product.product_name }}
          </p>
        </div>
        <list-component :addons="product.addons" />
      </div>
      <p  v-show="order.note" class="text-start px-5 py-2 text-[#D97706] bg-[#FEF3C7] text-sm" >Note: {{ order.note }}</p>

      <slot :order="order"></slot>
    </div>
  </div>
</template>
<script>
import ListComponent from "./ListComponent.vue";
export default {
  props: ["orders"],
  components: {
    ListComponent,
  },
  data() {
    return {
      // ordersData: []
    };
  },
  computed: {
    ordersData() {
      return this.orders;
    },
  },
  created() {
    setInterval(() => {
      this.updateTimeDifference();
    }, 1000);
  },
  methods: {
    getTimeDiff(dateTime) {
      const orderTime = new Date(dateTime).getTime();
      const now = new Date().getTime();
      let seconds = Math.floor((now - orderTime) / 1000);
      /*
       * Display hours and minutes
       */
      var minutes = "";

      let interval = seconds / 3600;
      if (interval > 1) {
        var hours = Math.floor(interval);
        minutes = Math.floor((interval % 1) * 60);
        if (hours < 10) {
          hours = `0${hours}`;
        }
        if (minutes < 10) {
          minutes = `0${minutes}`;
        }
        return `${hours} : ${minutes}`;
      }

      /*
       * Display minutes and seconds
       */
      interval = seconds / 60;
      if (interval > 1) {
        minutes = Math.floor(interval);
        var sec = Math.floor((interval % 1) * 60);
        if (minutes < 10) {
          minutes = `0${minutes}`;
        }
        if (sec < 10) {
          sec = `0${sec}`;
        }
        return `${minutes} : ${sec}`;
      }

      /**
       * displays seconds
       */
      return `00:${seconds}`;
    },
    /**
     * updates time difference every second
     */
    updateTimeDifference() {
      this.ordersData.forEach((order, index) => {
        this.ordersData[index] = {
          ...this.ordersData[index],
          created_at: order.created_at,
        };
      });
    },
  },
};
</script>
